<template>
    <div class="app">
        <ConnectionNotification v-if="!isOnline" message="Sem conexão com a internet!" :show-notification="!isOnline" />

        <router-view></router-view>
    </div>
</template>

<script lang="ts" setup>
    import { ref, onMounted, onBeforeUnmount } from 'vue';
    import ConnectionNotification from './components/ConnectionNotification.vue';

    const isOnline = ref<boolean>(navigator.onLine);

    const updateOnlineStatus = () => {
        isOnline.value = navigator.onLine;
    };

    onMounted(() => {
        window.addEventListener('online', updateOnlineStatus);
        window.addEventListener('offline', updateOnlineStatus);
    });

    onBeforeUnmount(() => {
        window.removeEventListener('online', updateOnlineStatus);
        window.removeEventListener('offline', updateOnlineStatus);
    });
</script>

<style lang="scss">
    .app {
        width: 100%;
        max-width: 1024px;
        height: 100%;
        max-height: 600px;
        margin: 0 auto;
    }

    * {
        box-sizing: border-box;
        margin: 0;
        padding: 0;
        font-family: 'Inter', sans-serif;
        outline: none;
    }
</style>
